<template>
  <div id="model_viewer">
    <template v-if="printing">
      <img :src="previewImage" alt="preview">
    </template>
    <template>
      <div id="renderer" ref="renderer"  :class="{'is-hidden' : printing }" />
      <slot name="ui" />
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :loader="'dots'" />
    </template>
  </div>
</template>

<script>

import Viewer3D from '@/webgl/Viewer3D'
import { EventBus, MESH_CLICK, GET_VIEWER_SCREENSHOT } from '@/webgl/EventBus'

export default {
  name: 'ModelViewer',
  components: {

  },

  props: {
    modelType: {
      type: String,
      default: '',
      required: false
    },
    colors: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default: () => ({})
    },
    specialCoupling: {
      type: Boolean,
      default: false
    },
    printing: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      controller: new Viewer3D(),
      isLoading: false,
      modelsPath: '/models/',
      models: {
        PlainEnd: {
          screenshot_key: 'plainendImg',
          key: 'plainend',
          filename: 'Plain End Simplified.glb',
          loaded: false
        },
        SemiFlush: {
          screenshot_key: 'semiflushImg',
          key: 'semiflush',
          filename: 'Cobra Simplified.glb',
          loaded: false
        },
        TubeAndCoupling: {
          screenshot_key: 'tubecouplingImg',
          key: 'tnc',
          filename: 'PremiumTC.glb',
          loaded: false
        },
        SemiPremiumTubeAndCoupling: {
          screenshot_key: 'semipremtubecouplingImg',
          key: 'sptnc',
          filename: 'SemiPremiumTC.glb',
          loaded: false
        }
      },
      defaultConfig: {
        gui: false,
        controls: false,
        zoom: 1,
        bgColor: 0xFFFFFF
      },
      previewImage: ''
    }
  },

  computed: {
    viewerSettings () {
      return { ...this.defaultConfig, ...this.config }
    }
  },

  watch: {
    modelType (next) {
      if (next) {
        this.setModel(next)
      }
    },
    colors: {
      handler: 'applyColors',
      deep: true
    },
    specialCoupling () {
      this.applyColors()
    },

    printing (next) {
      if (next) {
        this.setPreview()
      }
    }
  },

  mounted () {
    console.log(this.modelType, { ...this.viewerSettings })

    this.setup()
    this.setModel(this.modelType)

    EventBus.$on(MESH_CLICK, (mesh) => {
      if (mesh && mesh.visible) {
        // console.log(`Click ${mesh.name} (parent: ${mesh.parent && mesh.parent.name})`, mesh)
      }
    })
  },

  beforeDestroy () {
    EventBus.$off(MESH_CLICK)
    EventBus.$off(GET_VIEWER_SCREENSHOT)

    if (this.controller) {
      this.controller.destroy() // free up memory
    }
  },

  methods: {
    setup () {
      const controller = this.controller

      controller.init(this.$refs.renderer, this.viewerSettings)
      controller.animate()

      EventBus.$on(GET_VIEWER_SCREENSHOT, (callback) => callback(this.controller.screenshot))
    },

    async setModel (name) {
      const modelConfig = this.models[name]

      if (modelConfig && modelConfig.loaded) {
        this.controller.switchModel(modelConfig)
      } else if (modelConfig) {
        await this.loadModel(modelConfig)
      }
    },

    async loadModel (config) {
      this.setLoading(true)

      const { controller, modelsPath } = this
      const { filename } = config

      await controller.loadGLTF(filename, modelsPath)
      config.loaded = true
      controller.switchModel(config)

      this.applyColors()

      this.setLoading(false)
    },

    setLoading (value) {
      this.isLoading = value
    },

    setPreview () {
      this.controller.render()
      this.previewImage = this.controller.renderer.domElement.toDataURL('image/jpeg')
    },

    applyColors () {
      const colors = JSON.parse(JSON.stringify(this.colors))
      const modelKey = this.models[this.modelType] && this.models[this.modelType].key

      if (!colors['tncCouplingStripe4']) {
        colors['tncCouplingStripe4'] = 'none'
      }
      if (!colors['sptncCouplingStripe4']) {
        colors['sptncCouplingStripe4'] = 'none'
      }

      if (this.specialCoupling && modelKey) {
        let namespace
        if (modelKey === 'tnc') {
          namespace = 'tncCouplingStripe'
        } else if (modelKey === 'sptnc') {
          namespace = 'sptncCouplingStripe'
        } else if (modelKey === 'semiflush') {
          namespace = 'semiflushStripe'
        } else if (modelKey === 'plainend') {
          namespace = 'plainendStripe'
        }

        if (namespace) {
          // const getSpecialStripeNumber = (namespace) => {
          //   const keys = Object.keys(colors).filter(k => k.startsWith(namespace))
          //   const index = keys.findIndex(key => colors[key] === 'none' || colors[key] === 'null')

          //   return index + 2
          // }
          // const specialStripeIndex = getSpecialStripeNumber(namespace)
          // colors[namespace + specialStripeIndex] = '#000000'
          colors[namespace + 4] = '#000000'
        }
      }
      this.controller.setColors(colors)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~dat.gui/src/dat/gui/style.scss';

/deep/ .gui-wrap {
  position: absolute;
  right: 0;
  top: 0;
  background: #1a1a1a;
  text-align: left;
  max-height: 100%;
  overflow: auto;
  .title {
    font-size: inherit;
  }
  .cr {
    border-radius: 0;
    text-align: left;
    display: grid;
    justify-content: unset;
    width: 100%;
    height: auto;
  }
  .c .button {
    display: none;
  }
  .dg .closed li {
    display: block;
  }
}

#model_viewer {
  position: relative;
  overflow: hidden;
}
#renderer {
  width: 100%;
  height: 100%;
}
</style>
