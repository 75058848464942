import { fitCameraToSelection, getCanvasRelativePosition } from './utils'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

import * as THREE from 'three'
import { GUI } from 'dat.gui'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { EventBus, MESH_CLICK } from '@/webgl/EventBus'

const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: '.hdr'
  },
  // {
  //   name: 'Daylight (HDR 1k)',
  //   path: '/environment/hdr/modern_buildings_1k.hdr',
  //   format: '.hdr'
  // },
  {
    name: 'schadowplatz_1k',
    path: '/environment/hdr/schadowplatz_1k.hdr',
    format: '.hdr'
  }
]
const defaultEnv = environments[1].name
const getDefaultState = () => ({
  // camera
  cameraPosition: new THREE.Vector3(30, 5, 0),
  cameraFov: 5,

  // environment and background
  background: false,
  env: defaultEnv,

  // model
  rotationX: -1.57,
  rotationY: 0,
  rotationZ: -1.14,

  // lights
  hemiSkyColor: 0xffffbb,
  hemiGroundColor: 0x080820,
  hemiIntensity: 1,
  ambientColor: 0xffffff,
  ambientIntensity: 1,
  dirColor: 0xffffff,
  dirIntensity: 1,

  // renderer
  exposure: 0.8,

  // materials
  carbonMaterial: {
    metalness: 1,
    roughness: 1,
    clearcoat: 0.3,
    clearcoatRoughness: 0.5,
    envMapIntensity: 1,
    colorHex: 0x363636
  },

  metalMaterial: {
    metalness: 0.9,
    roughness: 0.1,
    clearcoat: 0.5,
    clearcoatRoughness: 0.2,
    envMapIntensity: 1,
    colorHex: 0xffffff
  },

  metalDarkMaterial: {
    metalness: 0.9,
    roughness: 0.15,
    clearcoat: 0.5,
    clearcoatRoughness: 0.2,
    envMapIntensity: 0.7,
    colorHex: 0x333333
  }
})

const prefs = {
  semiflush: {
    camera: {
      position: { x: -14.66948536266926, y: 3.303883736158248, z: -6.668966947638819 },
      rotation: { _x: -2.7734517674603705, _y: -1.096385731038664, _z: -2.8110537074643207, _order: 'XYZ' }
    },
    controls: {
      target: { x: -0.026178976867086645, y: 0.5976705614916829, z: 0.3469299283226798 }
    },
    model: {
      rotation: { _x: -1.57, _y: 0.0, _z: 3.14, _order: 'XYZ' }
    }
  },
  tnc: {
    camera: {
      position: { x: -15.85094437589331, y: 4.202498407644051, z: 6.284312089945159 },
      rotation: { _x: -0.50376475694522, _y: -1.1309139892181466, _z: -0.46262914916087067, _order: 'XYZ' }
    },
    controls: {
      target: { x: -0.13140838977499164, y: 0.6311986253668336, z: -0.1948130985294358 }
    },
    model: {
      rotation: { _x: -1.57, _y: 0.0, _z: 3, _order: 'XYZ' }
    }
  },
  sptnc: {
    camera: {
      position: { x: -15.85094437589331, y: 4.202498407644051, z: 6.284312089945159 },
      rotation: { _x: -0.50376475694522, _y: -1.1309139892181466, _z: -0.46262914916087067, _order: 'XYZ' }
    },
    controls: {
      target: { x: -0.13140838977499164, y: 0.6311986253668336, z: -0.1948130985294358 }
    },
    model: {
      rotation: { _x: 0, _y: 1.3, _z: 0, _order: 'XYZ' }
    }
  },
  plainend: {
    camera: {
      position: { x: 2.0273340643782394, y: 2.4375028089368054, z: 11.802278728100275 },
      rotation: { _x: -0.20921707253523017, _y: 0.14345486133140348, _z: 0.030345241362532062, _order: 'XYZ' }
    },
    controls: {
      target: { x: 0.2745965638049319, y: -0.08268113008270431, z: -0.06723639167348597 }
    },
    model: {
      rotation: { _x: -1.57, _y: 0.0, _z: -1.14, _order: 'XYZ' }
    }
  }
}

THREE.Cache.enabled = true

class Viewer3D {
  constructor () {
    this.renderer = null
    this.scene = null
    this.camera = null
    this.controls = null

    this.models = {}
    this.currentModelData = null

    this.loader = new GLTFLoader()
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco/')
    this.loader.setDRACOLoader(dracoLoader)

    this.pmremGenerator = null

    this.container = HTMLElement

    this._width = 0
    this._height = 0
    this._renderTick = null
    this._RAFID = null

    this.gui = null

    this.state = {
      ...getDefaultState(),
      reset: this.resetGUI.bind(this),
      log: this.getView.bind(this)
    }
  }

  init (container, options = {}) {
    const { gui, controls, bgColor, zoom } = options

    this.container = container

    const { width, height } = this.bounds
    const renderer = this.createRenderer({ width, height, bgColor })

    this.createScene('NEW SCENE')
    this.createCamera({ zoom })
    this.createOrbitControls({ enabled: controls })
    // this.createLights()
    this.enableSelectionTool()

    if (gui) {
      this.addGUI()
    }

    container.appendChild(renderer.domElement)

    window.addEventListener('resize', this.resize.bind(this), false)
  }

  createRenderer (config) {
    const {
      width,
      height,
      bgColor
    } = config

    this._width = width
    this._height = height

    const renderer = (this.renderer = new THREE.WebGLRenderer({
      // antialias: process.env.NODE_ENV === 'production'
      antialias: true
    }))
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding // default to THREE.LinearEncoding
    // renderer.toneMapping = THREE.LinearToneMapping // none
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    // renderer.toneMapping = THREE.CineonToneMapping
    // renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.toneMappingExposure = this.state.exposure

    renderer.name = renderer.type
    renderer.setSize(this._width, this._height)
    renderer.setPixelRatio(window.devicePixelRatio) // window.devicePixelRatio
    if (bgColor) {
      renderer.setClearColor(new THREE.Color(bgColor).getHex())
    } else {
      renderer.setClearColor(0x111111)
    }

    this.pmremGenerator = new THREE.PMREMGenerator(renderer)
    this.pmremGenerator.compileEquirectangularShader()

    return renderer
  }

  createScene (name) {
    const scene = (this.scene = new THREE.Scene())
    scene.name = name

    this.updateScene()

    return scene
  }

  createOrbitControls ({ enabled }) {
    if (!this.renderer || !this.renderer.domElement) {
      throw new Error('renderer domElement is required')
    } else if (!this.camera) {
      throw new Error('camera is required')
    }
    const controls = this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.enablePan = false
    controls.enableZoom = false
    controls.enabled = enabled

    return this.controls
  }

  createCamera (props) {
    const { zoom } = props
    const aspect = this._width / this._height
    const near = 0.1
    const far = 1000
    const position = this.state.cameraPosition
    const fov = this.state.cameraFov

    const camera = (this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far))
    camera.name = 'PERSPECTIVE'
    camera.position.copy(position) // camera view
    camera.zoom = zoom || 1

    this.scene.add(camera)

    return camera
  }

  fitCameraToObjects (objects, fitOffset) {
    const { camera, controls } = this

    fitCameraToSelection({ camera, objects, controls, fitOffset })
  }

  createLights () {
    const { camera, scene } = this
    const { ambientColor, ambientIntensity, dirColor, dirIntensity, hemiGroundColor, hemiSkyColor, hemiIntensity } = this.state

    const hemiLight = new THREE.HemisphereLight(hemiSkyColor, hemiGroundColor, hemiIntensity)
    hemiLight.name = 'hemi_light'
    scene.add(hemiLight)

    const ambientLight = new THREE.AmbientLight(ambientColor, ambientIntensity)
    ambientLight.name = 'ambient_light'
    camera.add(ambientLight)

    const dirLight = new THREE.DirectionalLight(dirColor, dirIntensity)
    dirLight.position.set(0.5, 0, 0.866) // ~60º
    dirLight.name = 'dir_light'
    camera.add(dirLight)

    this.lights = [hemiLight, ambientLight, dirLight]
  }

  addGUI () {
    const { container, state } = this

    const gui = this.gui = new GUI({ autoPlace: false, hideable: true, width: 300, overflow: 'auto' })

    // gui.add(state, 'reset').name('DEFAULT')

    const dispFolder = gui.addFolder('Display')
    dispFolder.open()
    dispFolder.add(state, 'env', environments.map((env) => env.name)).name('Environment').setValue(defaultEnv).onChange(() => this.updateScene())

    dispFolder.add(state, 'background').name('Background').onChange(() => this.updateScene())
    dispFolder.add(state, 'cameraFov').name('FOV').min(5).max(70).step(5).onChange(() => this.updateCamera())
    dispFolder.add(state, 'exposure').name('Exposure').min(0).max(2).step(0.01).onChange(() => this.updateScene())

    // const lightsFolder = gui.addFolder('Light Intensity')
    // lightsFolder.open()
    // lightsFolder.add(state, 'ambientIntensity').name('Ambient').min(0).max(2).step(0.01).onChange(() => this.updateLights())
    // lightsFolder.add(state, 'dirIntensity').name('Directional').min(0).max(2).step(0.01).onChange(() => this.updateLights())
    // lightsFolder.add(state, 'hemiIntensity').name('Hemisphere').min(0).max(2).listen().onChange(() => this.updateLights())

    const modelFolder = gui.addFolder('Model')
    modelFolder.open()
    modelFolder.add(state, 'rotationX').min(-Math.PI).max(Math.PI).step(0.01).name('RotationX').onChange(() => this.updateModel())
    modelFolder.add(state, 'rotationY').min(-Math.PI).max(Math.PI).step(0.01).name('RotationY').onChange(() => this.updateModel())
    modelFolder.add(state, 'rotationZ').min(-Math.PI).max(Math.PI).step(0.01).name('RotationZ').onChange(() => this.updateModel())

    const carbonMaterialFolder = gui.addFolder('Carbon material')
    carbonMaterialFolder.open()
    for (const key in state.carbonMaterial) {
      if (typeof state.carbonMaterial[key] !== 'object') {
        carbonMaterialFolder.add(state.carbonMaterial, key).min(0.0).max(1.0).step(0.01).onChange(() => this.updateMaterials())
      }
    }

    const metalMaterialFolder = gui.addFolder('Metal material')
    metalMaterialFolder.open()
    for (const key in state.metalMaterial) {
      if (typeof state.metalMaterial[key] !== 'object') {
        metalMaterialFolder.add(state.metalMaterial, key).min(0.0).max(1.0).step(0.01).onChange(() => this.updateMaterials())
      }
    }

    const metalDarkMaterialFolder = gui.addFolder('Metal Dark material')
    metalDarkMaterialFolder.open()
    for (const key in state.metalDarkMaterial) {
      if (typeof state.metalDarkMaterial[key] !== 'object') {
        metalDarkMaterialFolder.add(state.metalDarkMaterial, key).min(0.0).max(1.0).step(0.01).onChange(() => this.updateMaterials())
      }
    }

    gui.add(state, 'log').name('Log view')

    const guiWrap = document.createElement('div')
    container.appendChild(guiWrap)

    guiWrap.classList.add('gui-wrap')
    guiWrap.appendChild(gui.domElement)
    gui.open()
  }

  enableSelectionTool (targets) {
    const { camera, scene, renderer } = this
    const mouse = new THREE.Vector2(0, 0)
    const raycaster = new THREE.Raycaster()
    const canvas = renderer.getContext().canvas

    const raycast = (event) => {
      // 1. sets the mouse position with a coordinate system where the center of the screen is the origin

      const pos = getCanvasRelativePosition(event, canvas)
      mouse.x = (pos.x / canvas.width) * 2 - 1
      mouse.y = (pos.y / canvas.height) * -2 + 1 // note we flip Y

      // 2. set the picking ray from the camera position and mouse coordinates
      raycaster.setFromCamera(mouse, camera)

      const intersects = raycaster.intersectObjects(targets || scene.children, true)

      EventBus.$emit(MESH_CLICK, intersects[0] && intersects[0].object)
    }

    renderer.domElement.addEventListener('click', raycast, false)
  }

  async loadGLTF (filename, path = '/') {
    const { models, loader, scene } = this

    return new Promise((resolve) => {
      loader.setPath(path)

      // console.log('loading model...', filename)

      loader.load(filename, (gltf) => {
        const content = gltf.scene

        scene.add(content)

        models[filename] = content.uuid

        this.setMaterials(content)

        resolve(content)
      })
    })
  }

  async loadCubeMapTexture (environment) {
    const { path, format } = environment

    // no envmap
    if (!path) return Promise.resolve({ envMap: null })

    return new Promise((resolve, reject) => {
      if (format === '.hdr') {
        new RGBELoader()
          .setDataType(THREE.UnsignedByteType)
          .load(path, (texture) => {
            const envMap = this.pmremGenerator.fromEquirectangular(texture).texture
            this.pmremGenerator.dispose()

            resolve({ envMap })
          }, undefined, reject)
      } else if (format === '.jpg') {
        const cubeTexture = new THREE.CubeTextureLoader()
          .setPath(path)
          .load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'])

        resolve({ envMap: cubeTexture })
      } else {
        resolve({ envMap: null })
      }
    })
  }

  switchModel (config) {
    const { filename, key } = config
    const { scene, models } = this

    if (this.currentModelData) {
      const prev = scene.getObjectByProperty('uuid', this.currentModelData.uuid)
      prev.visible = false
    }

    if (models[filename]) {
      const next = scene.getObjectByProperty('uuid', models[filename])

      next.visible = true

      this.currentModelData = {
        uuid: next.uuid,
        ...config
      }
      this.updateViewState(prefs[key])
      this.fitCameraToObjects([next])
    } else {
      throw new Error('Not found!')
    }
  }

  setColors (colorsByName) {
    const { currentModelData, scene } = this

    if (currentModelData) {
      const { uuid, key } = currentModelData

      const object3D = scene.getObjectByProperty('uuid', uuid)

      const filteredColors = Object.keys(colorsByName)
        .filter(objKey => objKey.startsWith(key))
        .reduce((obj, key) => ({ ...obj, [key]: colorsByName[key] }), {})

      const colorMap = {
        plainendStripe1: 'stripe_01',
        plainendStripe2: 'stripe_02',
        plainendStripe3: 'stripe_03',
        plainendStripe4: 'stripe_04',
        semiflushStripe1: 'stripe_01',
        semiflushStripe2: 'stripe_02',
        semiflushStripe3: 'stripe_03',
        semiflushStripe4: 'stripe_04',
        tncField: 'box_field',
        tncCouplingStripe1: 'box_stripe_01',
        tncCouplingStripe2: 'box_stripe_02',
        tncCouplingStripe3: 'box_stripe_03',
        tncCouplingStripe4: 'box_stripe_04',
        tncTubeStripe1: 'pin_stripe_01',
        tncTubeStripe2: 'pin_stripe_02',
        tncTubeStripe3: 'pin_stripe_03',
        tncTubeStripe4: 'pin_stripe_04',
        sptncField: 'box_field',
        sptncCouplingStripe1: 'sptnc_coup_stripe_01',
        sptncCouplingStripe2: 'sptnc_coup_stripe_02',
        sptncCouplingStripe3: 'sptnc_coup_stripe_03',
        sptncCouplingStripe4: 'sptnc_coup_stripe_04',
        sptncTubeStripe1: 'sptnc_pipe_stripe_01',
        sptncTubeStripe2: 'sptnc_pipe_stripe_02',
        sptncTubeStripe3: 'sptnc_pipe_stripe_03',
        sptncTubeStripe4: 'sptnc_pipe_stripe_04'
      }

      const defaultColorHex = getDefaultState().carbonMaterial.colorHex
      const coupling = object3D.getObjectByProperty('name', 'box_field')

      for (const colorKey in filteredColors) {
        let color

        if (filteredColors[colorKey] === 'none' || filteredColors[colorKey] === 'null') {
          color = new THREE.Color(defaultColorHex)

          if ((colorKey.startsWith('tncCouplingStripe') || colorKey.startsWith('sptncCouplingStripe')) && coupling && coupling.material) {
            color = coupling.material.color
          } else if ((colorKey.startsWith('tncCouplingStripe') || colorKey.startsWith('sptncCouplingStripe')) && coupling && coupling.children.length) {
            coupling.traverseVisible(node => {
              if (node instanceof THREE.Mesh) {
                color = node.material.color
              }
            })
          }
        } else {
          color = new THREE.Color(filteredColors[colorKey])
        }

        if (color) {
          const targetName = colorMap[colorKey]
          const target = object3D.getObjectByProperty('name', targetName)

          // console.log({
          //   targetName,
          //   colorKey,
          //   color: color.getHexString(),
          //   filteredColors
          // })

          target && target.traverse((node) => {
            if (node instanceof THREE.Mesh) {
              node.material.color = color
              node.material.needsUpdate = true
            }
          })
        }
      }
    }
  }

  resize () {
    const { camera, renderer } = this

    if (renderer) {
      const { width, height } = this.bounds

      this._width = width
      this._height = height

      renderer.setSize(width, height)

      camera.aspect = width / height
      camera.updateProjectionMatrix()
    }
  }

  render () {
    if (!this.renderer) return
    if (this.controls && (this.controls.enableDamping || this.controls.autoRotate)) {
      this.controls.update() // only required if controls.enableDamping = true, or if controls.autoRotate = true
    }

    this.renderer.render(this.scene, this.camera)
  }

  animate (tick) {
    this._renderTick = tick
    this._RAFID = requestAnimationFrame(this.animate.bind(this))
    this.render()
  }

  async destroy () {
    cancelAnimationFrame(this._RAFID)

    window.removeEventListener('resize', this.resize)

    await this.dispose(this.scene)
    this.scene = null
    this.camera = null
    this.renderer.renderLists.dispose()
    this.renderer = null
  }

  dispose (object3D) {
    return new Promise((resolve) => {
      object3D.traverse((o) => {
        if (o.geometry) {
          o.geometry.dispose()
        }

        if (o.material) {
          if (o.material.length) {
            for (let i = 0; i < o.material.length; ++i) {
              o.material[i].dispose()
            }
          } else {
            o.material.dispose()
          }
        }
      })
      THREE.Cache.clear()
      resolve()
    })
  }

  updateModel () {
    const { currentModel } = this
    const { rotationX, rotationY, rotationZ } = this.state

    currentModel.rotation.set(rotationX, rotationY, rotationZ)

    this.fitCameraToObjects([currentModel])
  }

  setMaterials (content) {
    const { currentModel } = this

    if (!content) {
      content = currentModel
    }

    const { carbonMaterial, metalMaterial, metalDarkMaterial } = getDefaultState()

    const physicalMaterial = new THREE.MeshPhysicalMaterial()

    const getMergedMaterial = (base, originalMaterial, overrideMaterial) => {
      const { id, ...original } = originalMaterial
      const override = { ...original, ...overrideMaterial }

      for (const key in override) {
        base[key] = override[key]
      }

      return base
    }

    content.traverseVisible((node) => {
      if (node instanceof THREE.Mesh) {
        if (['carbon', 'box_field', 'box_stripe', 'pin_stripe'].some(key => node.material.name.includes(key))) {
          node.material = getMergedMaterial(physicalMaterial.clone(), node.material, carbonMaterial)
          node.material.color = new THREE.Color(carbonMaterial.colorHex)
        } else if (node.material.name.includes('metal_light')) {
          node.material = getMergedMaterial(physicalMaterial.clone(), node.material, metalMaterial)
          node.material.color = new THREE.Color(metalMaterial.colorHex)
        } else if (node.material.name.includes('metal_dark')) {
          node.material = getMergedMaterial(physicalMaterial.clone(), node.material, metalDarkMaterial)
          node.material.color = new THREE.Color(metalDarkMaterial.colorHex)
        } else {
          console.log('!!!', node.material.name)
        }

        node.material.needsUpdate = true
      }
    })

    physicalMaterial.dispose()
  }

  updateMaterials (content) {
    const { currentModel, state } = this

    if (!content) {
      content = currentModel
    }

    const { carbonMaterial, metalMaterial, metalDarkMaterial } = state

    content.traverseVisible((node) => {
      if (node instanceof THREE.Mesh) {
        if (['carbon', 'box_field', 'box_stripe', 'pin_stripe'].some(key => node.material.name.includes(key))) {
          Object.assign(node.material, carbonMaterial)
        } else if (node.material.name.includes('metal_light')) {
          Object.assign(node.material, metalMaterial)
        } else if (node.material.name.includes('metal_dark')) {
          Object.assign(node.material, metalDarkMaterial)
        }

        node.material.needsUpdate = true
      }
    })
  }

  updateCamera () {
    const { camera, state, currentModel } = this

    camera.fov = state.cameraFov
    camera.updateProjectionMatrix()

    this.fitCameraToObjects([currentModel])
  }

  async updateScene () {
    const { scene, renderer } = this
    const { background, exposure, env } = this.state
    const environment = environments.filter((entry) => entry.name === env)[0]

    if (environment) {
      const { envMap } = await this.loadCubeMapTexture(environment)
      scene.environment = envMap
    }

    scene.background = background ? scene.environment : null

    renderer.toneMappingExposure = exposure
  }

  updateLights () {
    const { hemiIntensity, dirIntensity, ambientIntensity } = this.state

    this.lights.forEach(light => {
      if (light instanceof THREE.HemisphereLight) {
        light.intensity = hemiIntensity
      } else if (light instanceof THREE.DirectionalLight) {
        light.intensity = dirIntensity
      } else if (light instanceof THREE.AmbientLight) {
        light.intensity = ambientIntensity
      }
    })
    this.renderer.toneMappingExposure = this.state.exposure
  }

  updateViewState (config = {}) {
    const { currentModel, camera, controls, gui, state } = this

    if (!currentModel) return

    for (const key in config) {
      if (key === 'model') {
        if (config.model.rotation) {
          currentModel.rotation.copy(config.model.rotation)
        }
      } else if (key === 'camera') {
        if (config.camera.position) {
          camera.position.copy(config.camera.position)
        }
        if (config.camera.rotation) {
          camera.rotation.copy(config.camera.rotation)
        }
      } else if (key === 'controls') {
        if (config.controls.target) {
          controls.target.copy(config.controls.target)
        }
      }
    }

    const { x, y, z } = currentModel.rotation

    state.rotationX = x
    state.rotationY = y
    state.rotationZ = z

    if (gui) {
      for (var i = 0; i < Object.keys(gui.__folders).length; i++) {
        var key = Object.keys(gui.__folders)[i]
        for (var j = 0; j < gui.__folders[key].__controllers.length; j++) {
          gui.__folders[key].__controllers[j].updateDisplay()
        }
      }
    }
  }

  resetGUI () {
    Object.assign(this.state, getDefaultState())

    this.updateModel()
    this.updateCamera()
    this.updateMaterials()
    this.updateScene()
  }

  getView () {
    // const { camera, controls, currentModel } = this

    // const config = {
    //   camera: {
    //     position: { ...camera.position.clone() },
    //     rotation: { ...camera.rotation.clone() }
    //   },
    //   controls: {
    //     target: { ...controls.target.clone() }
    //   },
    //   model: {
    //     rotation: { ...currentModel.rotation.clone() }
    //   }
    // }
    // console.log(JSON.stringify(config))
    // controls.reset()
  }

  get screenshot () {
    if (!this.currentModelData) return {}

    // 1) backup controls position
    this.controls.saveState()

    // 2) resize renderer
    this.renderer.setSize(400, 600)
    this.camera.aspect = 400 / 600
    this.updateViewState(prefs[this.currentModelData.key])
    this.fitCameraToObjects([this.currentModel], 0.8)

    // 3) get image data
    this.render()
    const imageData = this.renderer.domElement.toDataURL('image/jpeg')

    // 4) restore
    this.renderer.setSize(this._width, this.height)
    this.resize()
    this.controls.reset()

    return {
      imageType: 'image/jpeg',
      imageData,
      details: { ...this.currentModelData }
    }
  }

  get bounds () {
    return this.container.getBoundingClientRect()
  }

  get currentModel () {
    const { currentModelData, scene } = this

    return currentModelData ? scene.getObjectByProperty('uuid', currentModelData.uuid) : null
  }
}

export default Viewer3D
