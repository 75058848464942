import { Box3, Vector3 } from 'three'

export function fitCameraToSelection ({ camera, controls, objects, fitOffset = 1.2 }) {
  const box = new Box3()

  for (const object of objects) box.expandByObject(object)

  const size = box.getSize(new Vector3())
  const center = box.getCenter(new Vector3())

  const maxSize = Math.max(size.x, size.y, size.z)
  const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360))
  const fitWidthDistance = fitHeightDistance / camera.aspect
  const distance = (fitOffset * Math.max(fitHeightDistance, fitWidthDistance)) / camera.zoom
  const direction = controls.target.clone().sub(camera.position).normalize().multiplyScalar(distance)

  controls.maxDistance = distance * 10
  controls.target.copy(center)

  camera.near = distance / 100
  camera.far = distance * 100
  camera.updateProjectionMatrix()
  camera.position.copy(controls.target).sub(direction)

  controls.update()
}

export function getCanvasRelativePosition (event, canvas) {
  const rect = canvas.getBoundingClientRect()
  return {
    x: (event.clientX - rect.left) * canvas.width / rect.width,
    y: (event.clientY - rect.top) * canvas.height / rect.height
  }
}
